<template>
  <ul class="tab-list">
    <li v-for="(tab, idx) in tabs" :key="idx" class="tab-list__item">
      <button
        class="tab-list__button"
        :class="{ 'tab-list__button--active': activeTabIdx === idx }"
        @click="setActiveTab(idx)"
      >
        {{ tab.title }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabList',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    startTabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTabIdx: 0,
    };
  },
  computed: {
    isStartTabValid() {
      return (
        this.startTabIndex < this.tabs.length &&
        this.startTabIndex > 0
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isStartTabValid) this.setActiveTab(this.startTabIndex);
    },
    setActiveTab(index) {
      this.activeTabIdx = index;
      const currentTab = this.tabs[index];
      this.$emit('change-tab', { index, currentTab });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.tab-list {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  list-style-type: none;
  color: #787980;
  overflow: auto hidden;
  border-bottom: 1px solid rgba(217, 217, 217, 0.16);

  @include min-laptop() {
    column-gap: 40px;
  }
}

.tab-list__button {
  display: block;
  padding: 12px 0;
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  background: none;
  border: none;
  cursor: pointer;

  &:not(.tab-list__button--active) {
    color: inherit;
  }

  &:hover,
  &:focus {
    color: #f5f5f7;
  }
}

.tab-list__button--active {
  position: relative;
  color: #f5f5f7;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #f87d28 0%, #facc59 171.54%);
    border-radius: 6px 6px 0px 0px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
