<template>
  <main-layout class="padded">
    <div class="pvp-container jobfair">
      <div class="jobfair-header">
        <h1 class="title">{{ $t('jobfair.teams') }}</h1>
        <Button
          v-if="isLoggedIn"
          :text="$t('jobfair.profile_add')"
          :size="'regular'"
          :type="'secondary'"
          @click="profileAddClickHandler"
        />
      </div>
      <GamesFilter
        v-model="filter.search.gameId"
        :is-jobfair="true"
        @input="(gameId) => updateFilter({ search: { gameId } })"
        @filter-change="gamesFilterChangeHandler"
      >
        <template #title>{{ $t('games.filter') }}</template>
      </GamesFilter>

      <div class="jobfair__tab-list">
        <TabList
          :tabs="profilesList"
          @change-tab="({ index }) => updateFilter({ my: index })"
        />
      </div>

      <!--  -->
      <div class="container">
        <!-- ИГРОКИ / КОМАНДЫ -->
        <div v-if="!filter.my" class="filter-box">
          <div class="filter-type">
            <pvp-btn
              variant="clear"
              :class="{ active: !isTeams }"
              @click="updateFilter({ type: 'resume' })"
            >
              {{ $t('players.few') }}
            </pvp-btn>
            <pvp-btn
              variant="clear"
              :class="{ active: isTeams }"
              @click="updateFilter({ type: 'vacancy' })"
            >
              {{ $t('teams.few') }}
            </pvp-btn>
          </div>

          <div class="filter-title">
            <div class="filter-title-text">
              {{ $t('filter.single') }}
            </div>
            <pvp-btn
              variant="secondary"
              :icon-right="isFilterOpen ? 'arrow-up' : 'arrow-down'"
              @click="toggleFilter"
            >
              {{ $t('filter.single') }}
            </pvp-btn>
          </div>

          <div :class="{ open: isFilterOpen }" class="filter-border">
            <div class="filter-item">
              <div class="filter-item-title">
                {{ $t('global.age') }}
              </div>
              <age-range
                v-model="filter.age"
                @input="() => updateFilter()"
              />
            </div>
            <div v-if="filterParams.roles.length" class="filter-item">
              <div class="filter-item-title">
                {{ $t('players.role_single') }}
              </div>
              <pvp-btn
                v-for="(role, key) in filterParams.roles"
                :key="key"
                :variant="role.variant"
                @click="toggleRole(role.id)"
              >
                {{ role.label }}
              </pvp-btn>
            </div>
            <div class="filter-item">
              <div class="filter-item-title">
                {{ $t('global.target') }}
              </div>
              <BaseSelect
                :value="filter.search.target"
                :placeholder="$t('players.target_select')"
                :options="filterParams.targets"
                label-key="label"
                value-key="id"
                @input="
                  (id) =>
                    updateFilter({ search: { target: id || null } })
                "
              >
              </BaseSelect>
            </div>
            <pvp-btn
              v-if="needSkip"
              icon-left="cross"
              variant="secondary"
              class="filter-skip"
              @click="skipFilter(true)"
            >
              {{ $t('filter.skip') }}
            </pvp-btn>
          </div>
        </div>

        <loader v-if="isLoading" />
        <div v-else class="profile-list-wrapper">
          <div v-if="filter.my" class="profile-tabs">
            <h3>
              {{
                $t(
                  `jobfair.search_my_${
                    isTeams ? 'players' : 'teams'
                  }`,
                )
              }}
            </h3>
            <Button
              :text="
                $t(
                  `jobfair.search_my_show_${
                    isTeams ? 'teams' : 'players'
                  }`,
                )
              "
              :size="'small'"
              :type="'secondary'"
              @click="
                updateFilter({ type: isTeams ? 'resume' : 'vacancy' })
              "
            />
          </div>
          <div class="profile-list">
            <template v-if="list.length">
              <jobfair-box
                v-for="(profile, key) in list"
                :key="key"
                :type="filter.type"
                :profile="profile"
                @edit="toggleSettingsModal"
                @pushed="updateFilter"
              />
            </template>
            <div v-else class="profile-list-notfound">
              {{
                $t(`jobfair.notfound_${filter.my ? 'my' : 'other'}`)
              }}
            </div>
          </div>
        </div>
      </div>
      <!--  -->

      <pvp-pagination
        :disabled="isLoading"
        :has-perpage="false"
        :params="pagination"
        @onchange="({ page }) => updateFilter({ search: { page } })"
      />
    </div>

    <jobfair-settings-modal
      v-model="isSettingsModal"
      :settings-options="settingsOptions"
      @success="updateFilter"
    />
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import AgeRange from '@components/BaseComponents/AgeRange.vue';
import JobfairBox from '@components/Jobfair/JobfairBox.vue';
import JobfairSettingsModal from '@components/Jobfair/JobfairSettingsModal.vue';
import TabList from '@components/v2/TabList.vue';
import Button from '@components/v2/ui/Button.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'Jobfair',
  components: {
    JobfairSettingsModal,
    JobfairBox,
    AgeRange,
    GamesFilter,
    TabList,
    Button,
    BaseSelect,
  },
  data() {
    return {
      isSettingsModal: false,
      settingsOptions: {},
      isFilterOpen: false,
      isLoading: true,
      filter: {
        my: 0,
        type: 'resume',
        age: [0, 100],
        search: {
          gameId: [],
          page: 1,
          target: null,
          roles: [],
        },
      },
      profilesList: [
        {
          title: this.$t('jobfair.profile_common'),
        },
        {
          title: this.$t('jobfair.profile_my'),
        },
      ],
      pagination: {
        page: 1,
        perPage: 12,
        total: 12,
      },
    };
  },
  computed: {
    ...mapState('jobfair', ['dictionary', 'jobFairList']),
    ...mapGetters('profile', ['isLoggedIn', 'getCurrentProfile']),

    isTeams() {
      return this.filter.type === 'vacancy';
    },

    filterParams() {
      const { roles = {}, targets = [] } = this.dictionary || {};
      return {
        targets,
        roles:
          roles?.[this.filter.search.gameId?.[0]]?.map((role) => {
            role.variant = this.filter.search.roles?.includes(role.id)
              ? 'primary'
              : 'secondary';
            return role;
          }) || [],
      };
    },

    list() {
      const { roles, targets } = this.dictionary || {};
      return (
        this.jobFairList?.map((profile) => ({
          ...profile,
          contacts: profile.contacts.map((contact) => ({
            ...contact,
            icon:
              { facebook: 'fb' }[contact.contactType] ||
              contact.contactType,
          })),
          target: targets?.find(({ id }) => id === profile.target.id)
            ?.label,
          roles: profile.roles.reduce((result, { id }) => {
            const role = roles?.[profile.gameId]?.find(
              (role) => role.id === id,
            );
            if (role?.id) {
              result.push(role);
            }
            return result;
          }, []),
        })) || []
      );
    },

    needSkip() {
      const { age, search } = this.filter;
      const { target, roles } = search;
      return target || roles.length || age[0] !== 0 || age[1] !== 100;
    },
  },
  created() {
    this.fetchJobFairDictionary();
    if (this.isLoggedIn) {
      this.fetchUserProfile({
        hash: this.getCurrentProfile.hash,
        complex: true,
      });
    }
    this.fetchJobFair();
  },
  methods: {
    ...mapActions('jobfair', [
      'fetchJobFairDictionary',
      'fetchJobfairList',
    ]),
    ...mapActions('users', ['fetchUserProfile']),

    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },

    profileAddClickHandler() {
      this.toggleSettingsModal(true);
      // ======================================================
      // METRICS Турниры. Пользователь кликнул на кнопку добавить анкету
      const tmrParams = {
        category: 'pvp',
        action: 'teamfinder_add_click',
      };
      pushEvents('pvp_teamfinder_add_click', tmrParams);
      // ======================================================
    },

    gamesFilterChangeHandler(gameName) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока игры на напарниках
      const tmrParams = {
        category: 'pvp',
        action: 'teamfinder_games_click',
        label: gameName,
      };
      pushEvents('pvp_teamfinder_games_click', tmrParams);
      // ======================================================
    },

    toggleSettingsModal(isOpen = false, settingsOptions = {}) {
      this.isSettingsModal = isOpen;
      this.settingsOptions = {
        ...settingsOptions,
        cardType: this.filter.type,
      };
    },

    toggleRole(role) {
      if (this.filter.search.roles.includes(role)) {
        this.filter.search.roles = this.filter.search.roles.filter(
          (item) => item !== role,
        );
      } else {
        this.filter.search.roles.push(role);
      }
      this.isLoading = true;
      this.fetchJobFair();
    },

    updateFilter(params = {}) {
      params.search = params.search || {};
      params.search.page = params.search?.page || 1;
      this.filter = _.merge(this.filter, params);
      if (params.search?.gameId !== undefined) {
        this.filter.search.roles = [];
      }
      if (params.my !== undefined) {
        this.skipFilter();
      }

      this.isLoading = true;
      this.fetchJobFair();
    },

    fetchJobFair: function () {
      const {
        search,
        age: [minAge, maxAge],
        ...filter
      } = this.filter;

      const params = {
        ...filter,
        search: {
          ...search,
          minAge,
          maxAge,
          gameId: search.gameId?.[0],
        },
      };

      this.fetchJobfairList(params)
        .then((pagen) => {
          if (pagen?.totalCount >= 0) {
            this.pagination = {
              page: pagen.pageNum,
              total: pagen.totalCount,
              perPage: pagen.pageSize,
            };
          }
        })
        .finally(() => {
          this.isLoading = false;
          // ======================================================
          // METRICS Турниры. Использование фильтра на напарниках
          const tmrParams = {
            category: 'pvp',
            action: 'teamfinder_filter_click',
            label: this.isTeams ? 'Команда' : 'Игрок',
            url: window.location.href,
          };
          pushEvents('pvp_teamfinder_filter_click', tmrParams);
          // ======================================================
        });
    },

    skipFilter(needUpdate = false) {
      this.filter = {
        ...this.filter,
        age: [0, 100],
        search: {
          ...this.filter.search,
          target: null,
          roles: [],
          page: 1,
        },
      };

      if (needUpdate) {
        this.isLoading = true;
        this.fetchJobFair();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  line-height: 1;
}

.jobfair {
  @include max-tablet() {
    padding: 0 12px;
  }

  &-header {
    @include min-tablet() {
      display: flex;
      align-items: center;
      .button {
        margin-left: 28px;
      }
    }
    @include max-tablet() {
      padding-left: 12px;
      padding-right: 12px;

      .button {
        margin-top: 12px;
      }
    }
  }
}

.games-filter {
  margin-top: 30px;
  @include max-tablet() {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.tabs-menu {
  margin-top: 20px;
}

.container {
  margin-top: 20px;
  margin-bottom: 8px;
  @include min-tablet() {
    display: flex;
    align-items: stretch;
  }
}

.filter {
  &-box {
    border-radius: 6px;
    overflow: hidden;
    background-color: $dark-background;
    @include min-tablet() {
      padding-bottom: 40px;
      width: 260px;
      margin-right: 8px;
      flex-shrink: 0;
    }
    @include max-tablet() {
      padding-bottom: 20px;
    }
  }

  &-border {
    padding: 12px 20px 30px;
    @include tablet-only() {
      position: sticky;
      top: calc(#{$headerHeight} + 10px);
    }
    @include min-tablet() {
      border-radius: 2px;
      margin: 0 8px;
    }
    @include max-tablet() {
      display: none;
      &.open {
        display: block;
      }
    }
  }

  &-item {
    & + & {
      margin-top: 28px;
    }

    &-title {
      font-size: 14px;
      color: rgba(white, 0.5);
      margin-bottom: 8px;
    }

    .button {
      margin: 1px;
    }
  }

  &-type {
    display: flex;

    .button {
      font-size: 15px;
      width: 50%;
      height: 34px;
      background-color: $dark;
      color: #626263;

      &.active {
        background-color: $dark-background;
        color: white;
      }
    }
  }

  &-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 14px;

    &-text {
      @include max-tablet() {
        display: none;
      }
    }

    .button {
      width: 90%;
      @include min-tablet() {
        display: none;
      }
    }
  }

  &-skip {
    margin: 32px auto 0;
    display: flex;
    width: 160px;
  }
}

.loader {
  width: 100%;
  min-height: 80px;
}

.profile {
  &-list {
    @include max-desktop() {
      max-width: 100%;
      overflow: hidden;
    }
  }

  &-tabs {
    margin-top: 20px;
    margin-bottom: 40px;
    @include min-laptop() {
      display: flex;
      align-items: center;
    }

    .button {
      @include min-laptop() {
        margin-left: 10px;
      }
      @include max-laptop() {
        margin-top: 10px;
      }
    }
  }

  &-list {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;

    &-notfound {
      text-align: center;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      padding: 32px;
      background: #100f0f;
      border-radius: 6px;
    }
  }
}

.jobfair__tab-list {
  padding-top: 40px;
}

.profile-list-wrapper {
  width: 100%;
}
</style>
