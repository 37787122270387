<template>
  <div class="games-filter noselect">
    <headline v-if="!hideHeadline">
      <h2 slot="title">
        <slot name="title">{{ $t('games.popular') }}</slot>
      </h2>

      <div
        v-if="!asLinks && selectedGames.length && !isJobfair"
        slot="addon"
        class="reset-filter-link"
        @click="clearSelection"
      >
        <Icon name="cross" :size="16" />
        {{ $t('filter.skip') }}
      </div>
    </headline>

    <template v-if="!appIsMobile">
      <div class="games-list">
        <div
          v-if="!asLinks"
          class="game-switch selectable"
          :class="{ active: allGamesSelected }"
          @click="clearSelection"
        >
          <div class="text">{{ $t('games.all') }}</div>
        </div>
        <div
          v-for="game in gamesMain"
          :key="game.id"
          class="game-switch selectable"
          :class="{ active: game.selected }"
          @click="toggleGame(game)"
        >
          <div class="icon">
            <game-tag
              :id="game.id"
              :display-name="false"
              class="big"
            />
          </div>
          <div class="text">{{ game.shortName }}</div>
        </div>
        <div
          class="game-switch games-tags-dropdown"
          :class="{ active: !!activeTags.length }"
          @click="showGamesPopover"
        >
          <div class="text chevron">
            <div v-if="activeTags.length" class="count">
              +{{ activeTags.length }}
            </div>
            <Icon name="chevron" :size="16" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <pvp-btn
        variant="secondary"
        class="games-popover-button"
        :block="true"
        @click="showGamesPopover"
        >{{ $t('games.choose') }}
        <template v-if="selectedGame"
          >({{ selectedGame.name }})</template
        >
      </pvp-btn>
    </template>

    <transition name="fade">
      <div
        v-if="gamesPopover"
        v-click-outside="hideGamesPopover"
        class="games-tags-popover fade-animation"
      >
        <div class="tags-popover-header">
          <div class="tags-popover-title">
            {{ $t('games.selection') }}
          </div>
          <div class="close-button" @click="hideGamesPopover">
            <Icon name="cross" :size="16" />
          </div>
        </div>
        <div class="tags-popover-body">
          <div class="tags-list">
            <game-tag
              v-for="game in gamesTags"
              :id="game.id"
              :key="game.id"
              :class="{
                active: game.selected,
                transparent: !game.selected,
              }"
              @click="toggleGame(game)"
            ></game-tag>
          </div>
        </div>
        <div class="tags-popover-footer">
          <template v-if="!asLinks">
            <pvp-btn variant="secondary" @click="clearSelection">
              {{ $t('filter.clear') }}
            </pvp-btn>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

const mainGamesLength = {
  xl: 9,
  lg: 7,
  md: 5,
};

export default {
  name: 'GamesFilter',
  components: {
    Icon,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    toggleReset: {
      type: Boolean,
      default: false,
    },

    asLinks: {
      type: Boolean,
      default: false,
    },

    isJobfair: {
      type: Boolean,
      default: false,
    },

    hideHeadline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gamesPopover: false,

      selectedGames: this.value || [],
      selectedTags: [],
    };
  },
  computed: {
    ...mapGetters('application', [
      'getAppSize',
      'getGames',
      'getGame',
      'appIsMobile',
    ]),

    mainGamesLength() {
      if (this.getAppSize === 'sm') {
        return 0;
      }

      return (
        mainGamesLength[this.getAppSize] + (this.asLinks ? 1 : 0)
      );
    },

    games() {
      return this.getGames.reduce((result, game) => {
        game.selected = this.selectedGames.includes(game.id);
        if (this.isJobfair) {
          if (game.use_in_job_fair) {
            result.push(game);
          }
        } else {
          result.push(game);
        }
        return result;
      }, []);
    },

    gamesMain() {
      return this.games.slice(0, this.mainGamesLength);
    },

    gamesTags() {
      return this.games.slice(this.mainGamesLength);
    },

    activeTags() {
      return _.difference(
        this.selectedGames,
        this.gamesMain.map((g) => g.id),
      );
    },

    allGamesSelected() {
      return !this.selectedGames.length;
    },

    selectedGame() {
      return this.getGame(_.head(this.selectedGames));
    },
  },
  watch: {
    selectedGames(selectedGames) {
      this.$emit('input', selectedGames);
    },
    value(value) {
      this.selectedGames = value;
    },
    toggleReset() {
      this.clearSelection();
    },
  },
  methods: {
    showGamesPopover() {
      this.gamesPopover = true;
      this.selectedTags = this.activeTags.slice();
    },

    hideGamesPopover() {
      this.gamesPopover = false;
      this.clearSelectedTags();
    },

    clearSelectedTags() {
      this.selectedTags = [];
    },

    clearSelection() {
      if (this.selectedGames.length) {
        this.selectedGames = [];
      }
      this.$emit('filter-change', this.$t('games.all'));
    },

    toggleGame(game) {
      this.selectedGames = this.selectedGames.includes(game.id)
        ? []
        : [game.id];
      this.$emit('filter-change', game.name);
      this.hideGamesPopover();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin outer-border() {
  position: absolute;
  inset: -4px;
  content: '';
  border: 2px solid $azure;
  border-radius: 6px;
}

.chevron {
  color: #cdcdcf;
}

.games-filter {
  position: relative;

  .reset-filter-link {
    cursor: pointer;
    color: $azure;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.2em;
    }

    &:hover {
      color: $dodger-blue;
    }
  }

  .games-list {
    margin-top: 20px;
    display: grid;
    grid-column-gap: 8px;
    position: relative;
    z-index: 0;

    @include min-desktop() {
      grid-template-columns: repeat(10, 1fr) 60px;
    }

    @include max-desktop() {
      grid-template-columns: repeat(8, 1fr) 60px;
    }

    @include max-laptop() {
      grid-template-columns: repeat(6, 1fr) 60px;
    }

    @include max-tablet() {
      grid-template-columns: repeat(4, 1fr) 60px;
    }

    .game-switch {
      background: #2d2d30;
      border: 1px solid rgba(245, 245, 247, 0.12);
      border-radius: 8px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100px;
      cursor: pointer;

      .icon {
        $icon-size: 40px;
        width: $icon-size;
        height: $icon-size;
        line-height: $icon-size;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }

      .text {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #979797;

        .count {
          font-size: 0.6875rem;
        }
      }

      .icon + .text {
        margin-top: 8px;
      }

      &.active:before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        background: linear-gradient(
          90deg,
          #f87d28 0%,
          #facc59 171.54%
        );
        border-radius: 10px;
        z-index: -1;
      }

      &:hover,
      &.active {
        background-color: #595a61;

        .text {
          color: #f5f5f7;
        }
      }
    }

    .games-tags-dropdown {
      font-size: 22px;

      .icon {
        width: 28px;
        height: 28px;
        stroke: currentColor;
      }
    }
  }

  .games-tags-popover {
    position: absolute;
    top: auto;
    right: 0;
    margin-top: 10px;
    width: 700px;
    max-width: 100%;
    border-radius: 10px;
    background-color: #2d2d30;
    box-shadow: $default-box-shadow;
    z-index: 4;

    .tags-popover-header {
      position: relative;

      .tags-popover-title {
        padding: 20px 0;

        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }

      .close-button {
        position: absolute;
        top: 16px;
        right: 20px;
        color: white;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    .tags-popover-body {
      padding-left: 40px;
      padding-right: 40px;

      @include max-tablet() {
        padding-left: 20px;
        padding-right: 20px;
      }

      .tags-list {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;

        .game-tag {
          background-color: $dark-background;
          border-radius: 4px;

          position: relative;
          margin: 5px;
          padding: 5px 6px 5px 5px;
          cursor: pointer;

          &.active,
          &:hover {
            background-color: #595a61;
          }

          &.active:before {
            @include outer-border;
          }
        }
      }
    }

    .tags-popover-footer {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;

      @include max-tablet() {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }

  .games-popover-button {
    margin-top: 20px;
  }
}
</style>
